import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/src/app/(main)/calculator/CalculatorForm.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/ArticleAccessibilityMenu/ArticleAccessibilityTextSize.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/EmojiText/EmojiText.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/MDX/MDXOnClient.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/NewsOverview/NewsOverview.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Persona/PersonaCard.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Section/Section.module.sass");
import(/* webpackMode: "eager" */ "/app/src/resources/personas/images/alex.jpg");
import(/* webpackMode: "eager" */ "/app/src/resources/personas/images/jade.jpg");
import(/* webpackMode: "eager" */ "/app/src/resources/personas/images/lola.jpg");
import(/* webpackMode: "eager" */ "/app/src/resources/personas/images/noah.jpg");
import(/* webpackMode: "eager" */ "/app/src/resources/personas/images/rico.jpg");
import(/* webpackMode: "eager" */ "/app/src/resources/personas/images/tina.jpg");
import(/* webpackMode: "eager" */ "/app/src/resources/personas/images/vienna.jpg")