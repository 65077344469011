import ArticleAccessibilityMenu from '../ArticleAccessibilityMenu/ArticleAccessibilityMenu';
import ShareButton from '../ShareButton/ShareButton';

const H1 = ({
	children,
}: {
	children?: React.ReactNode | JSX.Element | string;
}) => {
	const isOnClient = typeof window !== 'undefined';

	return (
		<header aria-label="Artikeltitel" className="relative mb-2">
			<h1 className="relative z-1 mb-0">{children}</h1>
			<div className="mt-4 flex min-h-10 flex-col gap-2 sm:flex-row">
				{isOnClient && (
					<>
						<ArticleAccessibilityMenu />
						<div>
							<ShareButton />
						</div>
					</>
				)}
			</div>
		</header>
	);
};

export default H1;
