'use client';

import IconButton from '@/components/Button/IconButton';
import { mdiAccountGroup, mdiCalculator, mdiCurrencyEur } from '@mdi/js';
import Icon from '@mdi/react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

export const CalculatorForm = ({
	searchParams,
}: {
	searchParams?: { [key: string]: string | string[] | undefined };
}) => {
	const formRef = useRef<HTMLFormElement>(null);

	const [isClient, setIsClient] = useState(false);

	const people = searchParams?.people ? `${searchParams.people}` : '';
	const budget = searchParams?.budget ? `${searchParams.budget}` : '';

	useLayoutEffect(() => {
		setIsClient(true);
	}, []);

	useEffect(() => {
		if (formRef.current) {
			formRef.current.people.value = people;
			formRef.current.budget.value = budget;
		}
	}, [people, budget]);

	if (!isClient) return null;

	return (
		<div className="pb-8">
			<form
				ref={formRef}
				action="/calculator"
				method="get"
				className="relative mx-auto flex w-full max-w-md flex-col gap-2 rounded-lg bg-black/20 p-3 pb-8 shadow-md"
			>
				<p className="mx-4 pt-1 text-center font-bold text-white">
					Bereken hoe divers jouw doelgroep kan zijn
				</p>
				<div className="focus-within flex flex-col gap-2 pb-2">
					<Input
						type="number"
						name="people"
						defaultValue={people}
						label="Hoeveel mensen wil jij bereiken?"
						placeholder="Vul een getal in"
						icon={mdiAccountGroup}
					/>

					<Input
						type="number"
						name="budget"
						defaultValue={budget}
						label="Wat is je budget?"
						placeholder="Vul een getal in"
						icon={mdiCurrencyEur}
					/>
				</div>

				<div className="absolute -bottom-4 self-center">
					<IconButton
						type="submit"
						icon={mdiCalculator}
						label="Bereken"
						colorVariant="quaternary"
						title="Bereken hoe divers jouw doelgroep kan zijn"
					/>
				</div>
			</form>
		</div>
	);
};

const Input = ({
	type,
	name,
	defaultValue,
	label,
	placeholder,
	icon,
}: {
	type: string;
	name: string;
	defaultValue: string;
	label: string;
	placeholder: string;
	icon: string;
}) => {
	return (
		<div className="shadow-border-bottom-md mt-1 flex flex-col rounded-lg bg-white p-1 font-bold text-slate-800 after:rounded-lg">
			<label htmlFor={name} className="px-2 pt-2">
				{label}
			</label>
			<div className="relative flex items-center gap-1 ps-2">
				<Icon path={icon} size={1} />
				<input
					type={type}
					name={name}
					defaultValue={defaultValue}
					placeholder={placeholder}
					className="max-w-full flex-1 rounded-lg bg-white p-2 text-lg placeholder:text-slate-600"
				/>
			</div>
		</div>
	);
};

export default CalculatorForm;
