import { MDXComponents } from 'mdx/types';
import Link from 'next/link';
import { ReactNode } from 'react';
import AltDecisionTree from '../AltDecisionTree/AltDecisionTree';
import EmojiButton from '../Button/EmojiButton';
import EmojiText from '../EmojiText/EmojiText';
import NewsOverview from '../NewsOverview/NewsOverview';
import CheckList from './CheckList';
import H1 from './H1';
import Iframe from './Iframe';
import Image from './Image';
import MultipleChoice from './MultipleChoice';
import Personas from './Personas';
import SingleTip from './SingleTip';

const MDXComponentsShared: MDXComponents = {
	h1: H1,
};

export const MDXComponentsServer: MDXComponents = {
	...MDXComponentsShared,
	Image: () => null,
	Iframe: () => null,
	SingleTip: () => null,
	MultipleChoice: () => null,
	NewsOverview: () => null,
	AltDecisionTree: () => null,
	Personas: () => null,
	CheckList: () => null,
	EmojiButton: () => null,
};

const TextElementTags: Array<TextElementTag> = [
	'h1',
	'h2',
	'h3',
	'h4',
	'h5',
	'h6',
	'p',
];

export const MDXComponentsClient: MDXComponents = {
	...MDXComponentsShared,
	Image: Image,
	Iframe: Iframe,
	SingleTip: SingleTip,
	MultipleChoice: MultipleChoice,
	NewsOverview: NewsOverview,
	AltDecisionTree: AltDecisionTree,
	Personas: Personas,
	CheckList: CheckList,
	EmojiButton: EmojiButton,
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	a: ({ href, children }: { href?: string; children?: ReactNode }) => (
		<Link
			href={href ?? ''}
			target={
				href?.startsWith('http') || href?.startsWith('/attachments/')
					? '_blank'
					: undefined
			}
		>
			{children}
		</Link>
	),
	// Add all text elements to support emoji
	...TextElementTags.reduce(
		(acc, tag) => {
			// Skip h1 element
			if (tag === 'h1') return acc;
			acc[tag] = ({ children }: { children?: ReactNode | string }) => (
				<TextElement tag={tag}>{children}</TextElement>
			);
			return acc;
		},
		{} as Record<
			TextElementTag,
			({ children }: { children?: ReactNode | string }) => JSX.Element
		>,
	),
};
type TextElementTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';

const TextElement = ({
	tag,
	children,
}: {
	tag: TextElementTag;
	children?: ReactNode | string;
}) => {
	// Create an element with the tag name
	const Element = tag;
	return (
		<Element>
			<EmojiText>{children}</EmojiText>
		</Element>
	);
};
