'use client';

import Markdown, { Components } from 'react-markdown';
import { MDXComponentsClient } from './MDXComponentMap';

const MDXOnClient = ({ children }: { children: string }) => (
	<Markdown components={MDXComponentsClient as never as Components}>
		{children}
	</Markdown>
);

export default MDXOnClient;
